import { FeedbackPromptResponseDetails } from "./EmployeeFeedbackModel";

//NJExpPLA20DModel Prompt -> On submission records get updated in Dipa database.
export class NJExpFeedBackModel {
    EnterpriseId!: string;
    CardUniqueId!: string;
    FeedbackPromptComments!:string[];
    CategoryType!: string;
    ChannelType!: string;
    FeedbackPromptResponseDetails !: FeedbackPromptResponseDetails[];
    IsPostAnonymous !: boolean;
}